
import React, {useState, useRef} from 'react'

import { 
  HeroContainer, HeroBg, HeroMessage, HeroMTop, HName, HEmail, 
  HMessage, HPhone, HButton, VideoBg, HeroHeader, ImgBg
     } from './HeroElements';
import emailjs from 'emailjs-com';
import img from '../../images/HeroBgImg2.svg';
import vid from '../../videos/HeroBg.mp4';
import vid2 from '../../videos/HeroBg.webm';
import vid3 from '../../videos/HeroBg.ogv';



    function Hero() {
      const initialValues = { name: "", phone: "", email: "", message: ""};
      const [formValues, setFormValues] = useState(initialValues);
      const [formErrors, setFormErrors] = useState({});
      const [isSubmit, setIsSubmit] = useState(false);
  
  
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
      };
  
      const form = useRef();
  
  
  
  
   const sendEmail = (e) => {
     
      e.preventDefault();
      setFormErrors(validate(formValues));
      setIsSubmit(true);
      if (Object.keys(validate(formValues)).length === 0) {
  
     
      emailjs.sendForm('gmail', 'K&M-Template', form.current, 'asi1IXWXVQKV4AGlS')
        .then((result) => {
            console.log(result.text);
       
        }, (error) => {
            console.log(error.text);
        });
        setFormValues("")
      }
      setFormValues("")
      e.target.reset();
    }
    const validate = (v) => {
      const errors = {};
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!v.name) {
        errors.name = "Name field can't be empty";
      }
      if (!v.email) {
        errors.email = "Email field can't be empty";
      } else if (!regex.test(v.email)) {
        errors.email = "Incorrect email";
      }
  
      if (!v.phone) {
        errors.phone = "Phone field can't be empty";
      }
      if (!v.message) {
        errors.message = "Message field can't be empty";
      }
  
      return errors;
    };
 


        return (
                <HeroContainer id ={'home'}>
                    <HeroBg>
                      <HeroHeader> Your Residential Roofing Contractors in Tennessee! </HeroHeader>
                      <HeroMessage ref={form} onSubmit={sendEmail}>
                          <HeroMTop>Schedule your Free Inspection</HeroMTop>
                          <HName onChange={handleChange} value={formValues.name} id='name' name='name' type="text" placeholder="*Name" required={true}/>
                          <HPhone onChange={handleChange} value={formValues.phone} id='phone' name='phone' type="text" placeholder="*Phone" required={true}/>
                          <HEmail onChange={handleChange} value={formValues.email} id='email' name='email' type="text" placeholder="*Email" required={true}/>
                          <HMessage onChange={handleChange} value={formValues.message} id='message' name='message' type="text" placeholder="*Message" required={true}/>


                          <HButton>REQUEST FREE QUOTE →</HButton>
                          {Object.keys(formErrors).length === 0 && isSubmit ? (
        <div style = {{marginLeft: `50%`, transform: `translateX(-50%)`, color: `green`, 
        fontSize: `4rem`, position: `absolute`, zIndex: `9999999`, background: `black`, 
        borderRadius: `5px`, borderColor: `black` }} className="ui message success">  Message Sent!  </div>
      ) : (
        <pre></pre>
      )}
                      </HeroMessage>



                    </HeroBg>
                    <VideoBg autoPlay loop muted playsinline src={vid} >

                        <source src={vid} type="video/mp4"  />
                        <source src={vid2} type="video/webm" />
                        <source src={vid3} type="video/ogv" />
                    </VideoBg>
                    <ImgBg autoPlay loop muted playsinline src={img} />
                </HeroContainer>
        
    
          
      
        );
      }
      export default Hero;




