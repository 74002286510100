import React from 'react';
import { motion } from "framer-motion";
import { BottomToTop} from '../../components/animations/animations';
import "./services.scss";
import {  ServicesContainer, ServicesBg, ServicesImgHeader, ServicesInfoSection,
          ServicesInfoContainer, ServicesInfoHeading, ServicesInfoImg, ServicesInfoText, ServicesHeader } from './ServicesElements';

//import insp from '../../images/inspection.svg'
import main from '../../images/roofmaintenance.svg'
import rep from '../../images/roofrepair.svg'
//import claim from '../../images/claims.svg'
import head from '../../images/servicesMF.svg'





function Services({isOpen, toggle}) {


 


  return (
<>

<ServicesContainer id ={'services'}>
    <ServicesBg>

      <ServicesImgHeader style={{ backgroundImage:`url(${head})` }}>

      <ServicesHeader>OUR SERVICES</ServicesHeader>
      </ServicesImgHeader>
                
        
     
      <motion.div 
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{once:false, amount:0.5}}
        transition={{staggerChildren:0.5}}
    >
          <motion.div variants={BottomToTop} >

          <ServicesInfoSection>
                    <ServicesInfoContainer>
                      <ServicesInfoImg  style={{ backgroundImage:`url(${main})` }}/>
                      <ServicesInfoHeading>Storm Damage Inspection</ServicesInfoHeading>
                      <ServicesInfoText>
                      K&M offers a complimentary storm damage inspection. After a wind or hail event you may be worried about 
                      the current state of your roofing system. You can schedule a free inspection with K&M and a representative 
                      will thoroughly assess the current condition of your roof and let you know if your home has been affected by 
                      any recent storms. </ServicesInfoText>
                    </ServicesInfoContainer>

                    <ServicesInfoContainer>
                      <ServicesInfoImg  style={{ backgroundImage:`url(${rep})` }}/>
                      <ServicesInfoHeading>Roofing Repair & Replace</ServicesInfoHeading>
                      <ServicesInfoText>A K&M roof replacement will provide you with peace of mind. K&M only uses quality brand
                       shingles and offers a ten year workmanship warranty. If anything goes wrong with your roof due to our 
                       craftsmanship we will come and fix it for free. K&M also offers regular maintenance repairs in case you 
                       may not need a roof replacement but need some minor work done to keep your home without a leak. </ServicesInfoText>
                    </ServicesInfoContainer>



          </ServicesInfoSection>
          </motion.div>
          </motion.div>

    </ServicesBg>

</ServicesContainer>
  
</>
    

  );
}
export default Services;






/*
<ServicesInfoContainer>
<ServicesInfoImg  style={{ backgroundImage:`url(${claim})` }}/>
<ServicesInfoHeading>Claims & Restoration</ServicesInfoHeading>
<ServicesInfoText>Roofing with K & M can help you restore your property after any 
sudden or unexpected damages happen by using insurance claims. </ServicesInfoText>
</ServicesInfoContainer>

<ServicesInfoContainer>
<ServicesInfoImg  style={{ backgroundImage:`url(${insp})` }}/>
<ServicesInfoHeading>Roof Inspections</ServicesInfoHeading>
<ServicesInfoText> Our comprehensive checklist and drones allows us to inspect and
deliver immediate feedback on the conditions of your roof.
 </ServicesInfoText>
</ServicesInfoContainer>
*/