import React from 'react';
//import Video from '../../videos/HBG3.mp4';
import "./about.scss";
import { 
  AboutContainer,
  AboutBG,
  AboutImgContainer,
  AboutTextSection,
  AboutTextTop,
  AboutHSide,
  AboutTextHeader,
  AboutText,
  AboutTextContainer,
  AboutSvg,
  AboutImg,
  AboutRightBg,
  AboutHeader
  
} from './AboutElements';
import { motion } from "framer-motion";
import { BottomToTop, LeftToRight, RightToLeft, PopIn } from '../../components/animations/animations';

import bg from '../../images/aboutUsMF.svg';
import svg from '../../images/MemphisMade.svg';
import arbg from '../../images/AboutRightBg.svg';

function About() {
  



  



  return (
 
    
    <AboutContainer id= 'about' >

      <AboutBG >
          <AboutImgContainer >


          <AboutImg  style = {{ backgroundImage: `url(${bg})`}}/>
                    <AboutHeader>ABOUT US</AboutHeader>
              

              </AboutImgContainer>


                  <AboutTextSection>
                    <AboutRightBg style = {{ backgroundImage: `url(${arbg})`}}/>


                      <AboutTextContainer>
                      
                          <motion.div initial={"onscreen"} whileInView={"offscreen"} viewport={{once:true, amount:0.5}} transition={{staggerChildren:0.5}}>
                          <motion.div variants={LeftToRight} >
                              <AboutTextTop>We Are Committed To Provide</AboutTextTop>
                            </motion.div>
                          </motion.div>

                          <motion.div initial={"onscreen"} whileInView={"offscreen"} viewport={{once:true, amount:0.5}} transition={{staggerChildren:0.5}}>
                            <motion.div variants={BottomToTop} >
                              <AboutHSide/>
                            </motion.div>
                          </motion.div>

                          <motion.div initial={"onscreen"} whileInView={"offscreen"} viewport={{once:true, amount:0.5}} transition={{staggerChildren:0.5}}>
                            <motion.div variants={RightToLeft} >
                                <AboutTextHeader>High Quality Roofing Services</AboutTextHeader>
                            </motion.div>
                          </motion.div>
                      
                          <motion.div initial={"onscreen"} whileInView={"offscreen"} viewport={{once:true, amount:0.5}} transition={{staggerChildren:0.5}}>
                <motion.div variants={PopIn} >
                        <AboutText>
                                      K & M Renovation & Restoration is a licensed, bonded, and insured storm damage restoration company servicing the homes within the mid-south!
                                      We provide you with a free storm damage assessment where a trained inspector will come to examine your roof. The storm damage inspector will let 
                                      you know if your roof is in need of repairs or a replacement. 
                                      Our vetted team strives for quality work!
                                      <AboutSvg  style = {{ backgroundImage: `url(${svg})`}}/> 
                        </AboutText>
                          </motion.div>
              </motion.div>
                    </AboutTextContainer>

          </AboutTextSection>
      </AboutBG>
    
    </AboutContainer>
    
  );
}
export default About;