import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll';
import logo from '../../images/KandMLogo.svg';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from '../Sidebar/SidebarData';

import {   SidebarNav, SidebarWrap} from '../Sidebar/SidebarElements';
import SubMenu from '../Sidebar/SubMenu';
import "./Navbar.scss";
import {
     Nav, 
     NavbarContainer, 
     NavLogoCont,
     NavMenu, 
     NavItem,
     NavLinks, 
     NavBtn,
     NavNumber
} from './NavbarElements';

import { NavIcon } from '../Sidebar/SidebarElements';
const Navbar = ({ toggle}) => {
  
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = ()=> {
        if(window.scrollY >= 10) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <>
        <IconContext.Provider value={{ color: '#fff'}}>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogoCont style={{ backgroundImage:`url(${logo})` }} to='/' onClick={toggleHome} ></NavLogoCont>
              

             

                <NavMenu>
                    <NavItem >
                        <NavLinks to='about' smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>About</NavLinks>
                    </NavItem>
                    <NavItem >
                        <NavLinks to='services' smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>Services</NavLinks>
                    </NavItem>
                    <NavItem >
                        <NavLinks to='projects' smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>Projects</NavLinks>
                    </NavItem>

                    <NavItem >
                        <NavLinks to='testimonials'  smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>Testimonials</NavLinks>
                    </NavItem>

                    <NavItem>
                        <NavLinks to='contact' smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>Contact Us!</NavLinks>
                    </NavItem>

                    <NavBtn >
                        <NavNumber smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>(901) 501-5949</NavNumber>
                    </NavBtn>
                    
                </NavMenu>
                <NavIcon style={{ color: '#000' }} to='#'>
                        <FaBars style={{ color: '#8ca12c' }} onClick={showSidebar} />
                </NavIcon>
                 
                <SidebarNav sidebar={sidebar}>
                <SidebarWrap>
                    <NavIcon to='#'>
                    <AiIcons.AiOutlineClose  onClick={showSidebar} />
                    </NavIcon>
                    {SidebarData.map((item, index) => {
                    return <SubMenu item={item} key={index} />;
                    })}
                </SidebarWrap>
                </SidebarNav>

            </NavbarContainer>
        </Nav>
        </IconContext.Provider>
        </>
    );
};

export default Navbar;
