import React, {useState} from 'react';
import "./projects.scss";
import Carousel from "framer-motion-carousel";
import {
  ProjectsContainer,
  ProjectsBg,
  LeftBg,
  ProjectHeaderSection,
  PHead,
  PHeadTop,
  PHeadLine,
  PCarouselCont,
  PCollageSection,
  PCollageBg,
  PCollLT,
  PCollRT,
  PCollLL,
  PButtonArea,
  Col1,
  Col2,
  PButtonText


  
} from './ProjectElements';

import  rbp  from '../../images/RightBgProjects.svg'
import  lbp  from '../../images/LeftBgProjects.svg'
import  cb from '../../images/CollageBg.svg'
import  button from '../../images/Button.svg'


function Projects({isOpen, toggle}) {

  const [hover, setHover] = useState(false);

  const onHover = () => {
      setHover(!hover);
  }
  return (
    <ProjectsContainer id ={'projects'}>
      
      <ProjectsBg style={{ backgroundImage:`url(${rbp})` }} >

        <Col1>
              <LeftBg style={{ backgroundImage:`url(${lbp})` }}/>




              <ProjectHeaderSection>
                  <PHeadTop>FOUNDED IN MEMPHIS, TENNESSEE <PHeadLine/></PHeadTop>
                  <PHead>OUR PROJECTS</PHead>
                 
              </ProjectHeaderSection>

              <PCarouselCont>
                    <Carousel>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((item, i) => (
                    <img
                      draggable="false"
                      src={`./carousel/${item}.png`}
                      key={i}
                      width="100%"
                      alt=""
                    />
                  ))}
                </Carousel>

              </PCarouselCont>
        </Col1>



        <Col2>
           <PCollageSection>
          
                <PCollageBg style={{ backgroundImage:`url(${cb})` }}/>
                
                <PCollLT>(901) 501-5949</PCollLT>
                <PCollRT>CALL TODAY FOR A FREE QUOTE</PCollRT>
                <PCollLL/>
            </PCollageSection>
          <PButtonArea  onMouseEnter={onHover} 
                    onMouseLeave={onHover} to ='/gallery' 
                    onClick={toggle} style={{ backgroundImage:`url(${button})`}}>
                    <PButtonText>
                
                      </PButtonText>
                    </PButtonArea>
                    
        </Col2>
      </ProjectsBg>
    </ProjectsContainer>
  );
}
export default Projects;