import "./Connect.scss";
import bg from '../../images/ContactImg.svg'



import React, { useRef,  useState} from 'react';

import {
  ContactContainer, ContactContainerImg, CMessageCont, CHeader, CName,
   CAddress, CCity, CZip, CPhone, CEmail, CMessage, CButton, ErrL
} from './ContactElements';
import emailjs from 'emailjs-com';



function Contact({isOpen, toggle}) {

  // This function validates emails

    const initialValues = { name: "", address: "", city: "", zip: "", phone: "", email: "", message: "", error: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [name, setName] = useState("");

    const handleChange = (e) => {
      const { name, value } = e.target;
     
      
      setFormValues({ ...formValues, [name]: value });
    };

    const form = useRef();




 const sendEmail = (e) => {
   
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length === 0) {

   
    emailjs.sendForm('gmail', 'K&M-Template', form.current, 'asi1IXWXVQKV4AGlS')
      .then((result) => {
          console.log(result.text);
          
     
      }, (error) => {
          console.log(error.text);
       });
      
      }
      setIsSubmit(true);
      setFormValues("")
      e.target.reset();
    }

    const validate = (v) => {
      const errors = {};
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!v.email) {
        errors.email = "Refresh to send another message";
      } else if (!regex.test(v.email)) {
        errors.email = "Refresh to send another message";
      }
      if (!v.name) {
        errors.name = "Name field can't be empty";
      }
  
      if (!v.address) {
        errors.address = "Address field can't be empty";
      }
      if (!v.city) {
        errors.city = "City field can't be empty";
      }
      if (!v.zip) {
        errors.zip = "Zipcode field can't be empty";
      }
      if (!v.phone) {
        errors.phone = "Phone field can't be empty";
      }
      if (!v.message) {
        errors.message = "Message field can't be empty";
      }
  
      return errors;
    };
  
  





  return (
<>
<ContactContainer id= 'contact'>
    <ContactContainerImg  style = {{ backgroundImage: `url(${bg})`}}/>
 
        <CMessageCont ref={form} onSubmit={sendEmail}>
    
            <CHeader>Schedule Your Inspection </CHeader>
            <div className="field">
                <CName onChange={handleChange} value={formValues.name} id='name' name='name' type="text" placeholder="*Name" required={true}/>
            </div>
         

            <div className="field">
                <CAddress onChange={handleChange} value={formValues.address} id='address' name='address'  type="text" placeholder="*Address" required={true}/>
            </div>
           

            <div className="field">
                <CCity onChange={handleChange} value={formValues.city} id='city' name='city' type="text" placeholder="*City" required={true}/>
            </div>
    
           
            <div className="field">
                <CZip  onChange={handleChange} value={formValues.zip} id='name' name='zip' type="text" placeholder="*Zip" required={true}/>
            </div>
  
           
            <div className="field">
                <CPhone onChange={handleChange} value={formValues.phone} id='phone' name='phone' type="text" placeholder="*Phone" required={true}/>
            </div>
        

            <div className="field">
                <CEmail onChange={handleChange} value={formValues.email} id='email' name='email' type="text" placeholder="*Email" required={true}/>
            </div>
            <ErrL for="email">{formErrors.email}</ErrL>

            <div className="field">
                <CMessage onChange={handleChange} value={formValues.message} id='message' name='message' type="text" placeholder="*Message" required={true}/>
            </div>
         

            <CButton>SEND</CButton>
            
        </CMessageCont>
        {Object.keys(formErrors).length === 0 && isSubmit ? (
        <div style = {{marginLeft: `50%`, transform: `translateX(-50%)`, color: `green`, 
        fontSize: `4rem`, position: `absolute`, zIndex: `9999999`, background: `black`, 
        borderRadius: `5px`, borderColor: `black` }} className="ui message success">  Message Sent!  </div>
      ) : (
        <pre></pre>
      )}
</ContactContainer>
</>
  );
}
export default Contact;